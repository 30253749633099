import { DirectLine } from 'botframework-directlinejs';
import React from 'react';
import ReactWebChat from 'botframework-webchat';
import { Modal, Button } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';
import { Clients, ErrorHandler } from '../../api';
import { eventTypes, createEvent } from '../../utils/analytics';

import './ChatBot.scss';

class ChatBot extends React.Component {
  _userID = '';
  _isMounted = false;
  constructor(props) {
    super(props);

    const timestamp = Date.now();

    this.state = { isOpen: false, token: null, timestamp };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCustomer?.id !== this.props.selectedCustomer?.id) {
      this._fetch();
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _getBotFeatureId = async () => {
    let res;
    try {
      res = await Clients.getFeatures(this.props.selectedCustomer.id);
    } catch (err) {
      ErrorHandler.error(err);
    }
    return res?.body?.find(f => f.name === 'virtual_assistant')
      ?.integrations?.[0]?.id;
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    const { selectedCustomer } = this.props;

    const integrationId = await this._getBotFeatureId();

    let res;
    try {
      res = await Clients.getClientIntegration({
        clientId: selectedCustomer?.id,
        integrationId,
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let token = null;

    if (res?.body?.fields?.length) {
      token = res.body.fields[0].value;
    }
    this.safeSetState({ token, isLoading: false });
  };

  handleOpen = () =>
    this.safeSetState({ isOpen: true }, () => {
      createEvent({ action: eventTypes.GET_HELP, detail: this._userID });
    });

  handleClose = () =>
    this.safeSetState({
      isOpen: false,
      /* when the user closes the chatbot, update the timestamp to force a new clientId, which creates a new conversation
       * if the user opens the chat again
       */
      timestamp: Date.now(),
    });

  render() {
    const { isOpen, token, timestamp } = this.state;
    const { userProfile } = this.props;

    this._userID =
      userProfile?.external_details?.connectwise &&
      `${timestamp}:${userProfile.external_details?.connectwise?.account_id}:${userProfile.external_details?.connectwise?.contact_id}`;
    const username = [userProfile?.first_name, userProfile?.last_name]
      .filter(f => !!f)
      .join(' ');

    const userInitials = `${userProfile?.first_name?.charAt(
      0
    )}${userProfile?.last_name?.charAt(0)}`;

    return (
      <Modal
        closeIcon
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        open={isOpen}
        size={'tiny'}
        className={'ChatBot'}
        trigger={<Button primary>Get Help</Button>}>
        <Modal.Header>Corsica Virtual Assistant</Modal.Header>
        <Modal.Content style={{ height: '500px', overflowY: 'auto' }}>
          {!!token && (
            <ReactWebChat
              directLine={
                new DirectLine({
                  token,
                })
              }
              styleOptions={{
                primaryFont: "'Open Sans', 'Arial', 'sans-serif'",
                newMessagesButtonFontSize: '75%',
                botAvatarInitials: 'CT',
                botAvatarBackgroundColor: '#2B5597',
                userAvatarInitials: userInitials,
                userAvatarBackgroundColor: '#51AA42',
                suggestedActionBorderRadius: 2,
                suggestedActionBorderWidth: 1,
                suggestedActionBorderColor: '#E2E2E2',
                bubbleBackground: '#F2F2F2',
                bubbleBorderColor: '#E2E2E2',
                bubbleFromUserBackground: '#F2F2F2',
                bubbleFromUserBorderColor: '#E2E2E2',
              }}
              userID={this._userID}
              username={username}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

const WrappedChatBot = props => (
  <AppContext.Consumer>
    {({ selectedCustomer, userProfile }) => (
      <ChatBot
        {...props}
        selectedCustomer={selectedCustomer}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedChatBot;
