import React from 'react';

import { history } from '../../store';
import { Ticket, ErrorHandler } from '../../api';
import { environment } from '../../environment';
import { eventTypes, createEvent } from '../../utils/analytics';
import { AddTicketModal } from '../';
import { AppContext } from '../../AppContext';

const COMPONENT_STATES = {
  CREATE: 'create',
  CREATE_SPARK: 'create spark',
  SPARK_SELECT_OWNER: 'client select',
  CONFIRM: 'confirm',
  SUBMITTED: 'submitted',
  CREATE_SPARK_CLIENT: 'create spark client',
  CREATE_SPARK_CORSICA: 'create spark corsica',
};

class CreateTicketWrapper extends React.Component {
  _isMounted = false;
  _user_cw_contact_id = null;
  _starting_state = null;

  constructor(props) {
    super(props);

    this._user_cw_contact_id =
      props.userProfile?.external_details?.connectwise?.contact_id;

    // client is not always resoved before this is loaded... fun times.
    let componentState = props.client
      ? this.initComponentState({
          client: props.client,
          userProfile: props.userProfile,
        })
      : COMPONENT_STATES.CREATE;

    this.state = {
      title: '',
      description: '',
      isSubmitting: false,
      isVisibleCreateTicketModal: props.isVisible,
      isUploading: false,
      uploadProgress: 0,
      componentState,
      selectedClientId: null,
      selectedClientName: null,

      optionsAssignedTo: [],

      optionsPriority: [],
      optionsType: [],
      optionsSubtype: [],
      assignedTo: '',
      priority: '',
      type: '',
      subtype: '',
      isError: false,
      isLoadingAttributes: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const clientId = this.props.client?.id;
    const prevClientId = prevProps.client?.id;
    if (clientId !== prevClientId) {
      this.safeSetState({
        componentState: this.initComponentState({
          client: this.props.client,
          userProfile: this.props.userProfile,
        }),
      });
    }
  }

  initComponentState = ({ client, userProfile }) => {
    /*
=====================================
if spark_client
    if user is a spark user
        ask user where to create
    else
        if creation priority = spark
            create on spark board
        else
            create on service desk
else
    create on service desk
=====================================
     */

    const isSparkClient = client?.is_spark_client;
    const canManageTickets = client?.manages_tickets;
    const isSparkUser = userProfile?.is_spark_user;
    const isCorsicaUser =
      userProfile?.client_id === environment.current.corsicaCustomerId;

    let componentState = COMPONENT_STATES.CREATE;

    if (isSparkClient) {
      if (isCorsicaUser) {
        componentState = COMPONENT_STATES.CREATE;
      }
      // if it is a spark client
      else if (isSparkUser) {
        // if it is a spark enabled user, let them pick the board
        componentState = COMPONENT_STATES.SPARK_SELECT_OWNER;
      } else {
        // if it is a spark client, but not a spark enabled user...
        if (canManageTickets) {
          // if they have the boolean enabled to allow them to default to spark board, then do so
          componentState = COMPONENT_STATES.CREATE_SPARK;
        } else {
          // else just let them spark create
          componentState = COMPONENT_STATES.CREATE;
        }
      }
    }

    this._starting_state = componentState;

    return componentState;
  };

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  fetchAttributes = async () => {
    this.safeSetState({ isLoadingAttributes: true });
    let res;
    try {
      res = await Ticket.attributes(this.state.selectedClientId);
    } catch (err) {
      ErrorHandler.error(err);
    }
    const newState = { isLoadingAttributes: false };
    if (res?.body?.data) {
      const { status, priority, assigned_to, category, type } = res.body.data;
      newState.optionsStatus = status;
      newState.optionsPriority = priority;
      newState.optionsAssignedTo = assigned_to;
      newState.optionsType = category;
      newState.optionsSubtype = type;
    }
    this.safeSetState(newState);
  };

  cleanFileName = str => {
    return str.replace(/([^a-z0-9 -.]+)/gi, '').replace(/ /g, '_');
  };

  handleClearNewTicketForm = () => {
    this.safeSetState({
      files: [],
      description: '',
      title: '',
      assignedTo: '',
      priority: '',
      type: '',
      subtype: '',
      selectedClientId: '',
      selectedClientName: '',
      isError: false,
    });
  };

  handleHideTicketCreateModal = () => {
    const { componentState } = this.state;
    this.safeSetState(
      {
        isVisibleCreateTicketModal: false,
        componentState: this._starting_state,
      },
      () => {
        this.handleClearNewTicketForm();
        if (
          componentState === COMPONENT_STATES.SUBMITTED &&
          (window.location.pathname === '/my-organization/tickets' ||
            window.location.pathname === '/my-tickets')
        ) {
          window.location.reload(true);
        }
      }
    );
  };

  handleFiles = files => {
    // dropzone returns an object on error instead of the array, so this prevents errors from that
    this.safeSetState({ files: Array.isArray(files) ? files : [] });
  };

  handleSelectClient = ({
    selectedClientId,
    selectedClientName,
    alsoUpdateState,
  }) => {
    const newState = { selectedClientId, selectedClientName };
    let callback = () => null;
    if (alsoUpdateState) {
      // if they selected corsica as the ticket handler
      if (selectedClientId === environment.current.corsicaCustomerId) {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CORSICA;
        // if they select corsica, then default them as the assignee
        newState.assignedTo = this._user_cw_contact_id;
      } else {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CLIENT;
      }
      callback = this.fetchAttributes;
    }
    this.safeSetState(newState, callback);
  };

  handleSubmit = () => {
    this.safeSetState({ isSubmitting: true });

    const {
      componentState,
      files,
      title,
      description,
      assignedTo,
    } = this.state;
    const { userProfile } = this.props;

    const company = userProfile?.client_name;
    const userLastName = userProfile?.last_name;
    const userFirstName = userProfile?.first_name;

    const userName = [userFirstName, userLastName].filter(f => !!f).join(' ');
    const nameAndCompany = [userName, company].filter(f => !!f).join(' at ');
    const createdByTitle = nameAndCompany
      ? `Created by: ${nameAndCompany}`
      : '';
    const req = Ticket.create();

    const updatedDescription = `${createdByTitle}\nCreated from view: ${window
      .location.pathname + window.location.search}\n--\n${description}`;

    req.field('title', title).field('description', updatedDescription);
    if (componentState === COMPONENT_STATES.CREATE_SPARK_CORSICA) {
      req.field('spark_client_owned', false);
      if (assignedTo) {
        req.field('contact_id', assignedTo);
      }
    }
    if (componentState === COMPONENT_STATES.CREATE_SPARK) {
      // this is a user that is at a spark client, but is not a spark user, but they have manage spark tickets enabled
      req.field('spark_client_owned', true);
      req.field('queue_id', '55'); // spark board
    }
    if (componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT) {
      req.field('spark_client_owned', true);
      const { type, subtype, priority } = this.state;
      if (type) {
        req.field('category_id', type);
      }
      if (subtype) {
        req.field('type_id', subtype);
      }
      if (assignedTo) {
        req.field('contact_id', assignedTo);
      }
      if (priority) {
        req.field('priority_id', priority);
      }
      req.field('queue_id', '55'); // all client tickets go to spark board
    }
    for (let i in files) {
      const file = files[i];
      req.attach('attachments[]', file, this.cleanFileName(file.name));
    }
    req
      .on('progress', event => {
        if (event.direction === 'upload') {
          this.setState({ isUploading: true, uploadProgress: event.percent });
        }
      })
      .then(res => {
        const ticketId = res?.body?.ticket_number || '';
        if (componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT) {
          createEvent({
            action: eventTypes.SPARK_CREATE_TICKET,
            detail: window.location.pathname,
          });
        } else {
          createEvent({
            action: eventTypes.CREATE_TICKET,
            detail: window.location.pathname,
          });
        }

        this.safeSetState({
          isSubmitting: false,
          componentState: COMPONENT_STATES.SUBMITTED,
          isUploading: false,
          ticketId,
        });
      })
      .catch(err => {
        this.safeSetState(
          {
            isSubmitting: false,
            isUploading: false,
            isError: true,
          },
          () => {
            ErrorHandler.bug(`ERROR::Ticket_Create::${err}`);
          }
        );
      });
  };
  handleChangeTitle = e => {
    this.safeSetState({ title: e.target.value });
  };
  handleChangeDescription = e => {
    this.safeSetState({ description: e.target.value });
  };
  handleContinue = () => {
    const newState = {};
    const { selectedClientId } = this.state;

    let callback = () => null;
    // if it is a spark user, send them to one of the two spark create views
    if (this.props.userProfile?.is_spark_user) {
      // if the user selected corsica to handle the ticket, then send them to corsica spark create view
      if (selectedClientId === environment.current.corsicaCustomerId) {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CORSICA;
        // if they select corsica to handle the ticket, then defaul assign them to the ticket
        newState.assignedTo = this._user_cw_contact_id;
      } else {
        newState.componentState = COMPONENT_STATES.CREATE_SPARK_CLIENT;
      }
      callback = this.fetchAttributes;
    } else {
      newState.componentState = COMPONENT_STATES.CREATE;
    }
    this.safeSetState(newState, callback);
  };
  handleSoftCancel = () => {
    // has anything been changed? if not, just close it
    const { title, description, files } = this.state;
    if (title || description || (files && files.length > 0)) {
      this.safeSetState({ componentState: COMPONENT_STATES.CONFIRM });
    } else {
      this.handleHideTicketCreateModal();
    }
  };
  handleGoToMyTickets = () => {
    this.handleHideTicketCreateModal();
    history.push('/my-tickets');
  };
  handleGoToMyTicketDetails = () => {
    this.handleHideTicketCreateModal();
    history.push(`/my-tickets/${this.state.ticketId}`);
  };
  handleGoToTicketDetails = () => {
    this.handleHideTicketCreateModal();
    history.push(`/my-organization/tickets/${this.state.ticketId}`);
  };
  handleShowTicketCreateModal = () => {
    this.handleClearNewTicketForm();
    this.safeSetState({
      isVisibleCreateTicketModal: true,
      componentState: this._starting_state,
    });
  };

  handleCreateTicketClick = () => {
    this.handleShowTicketCreateModal();
  };

  handleSelect = (text, value) => {
    this.safeSetState({ [text]: value });
  };

  render() {
    const { children } = this.props;
    const { selectedClientName } = this.state;
    return (
      <React.Fragment>
        <AddTicketModal
          {...this.state}
          isSparkUser={this.props.userProfile?.is_spark_user}
          corsicaId={environment.current.corsicaCustomerId}
          clientName={
            /* This `clientName` field is to notify the user which client the ticket will be associated with.
             * This is a little complicated. if it is a spark user, they can select their client or corsica
             * to assign the ticket to. This is where we get the `selectedClientName`.
             * if its not a spark user, then this will default to the client id of the
             * current user, unless it is a corsica user, and they have selected a different client in the
             * customer select, then that is the client id that will be used.
             * */
            selectedClientName || this.props.clientName
          }
          COMPONENT_STATES={COMPONENT_STATES}
          userProfile={this.props.userProfile}
          handleHideTicketCreateModal={this.handleHideTicketCreateModal}
          handleChangeTitle={this.handleChangeTitle}
          handleChangeDescription={this.handleChangeDescription}
          handleFiles={this.handleFiles}
          handleSubmit={this.handleSubmit}
          handleContinue={this.handleContinue}
          handleSoftCancel={this.handleSoftCancel}
          handleSelectClient={this.handleSelectClient}
          handleGoToMyTickets={this.handleGoToMyTickets}
          handleGoToMyTicketDetails={this.handleGoToMyTicketDetails}
          handleGoToTicketDetails={this.handleGoToTicketDetails}
          handleAssignedToChange={(e, opt) => {
            this.handleSelect('assignedTo', opt?.value);
          }}
          handlePriorityChange={(e, opt) =>
            this.handleSelect('priority', opt?.value)
          }
          handleTypeChange={(e, opt) => this.handleSelect('type', opt?.value)}
          handleSubtypeChange={(e, opt) =>
            this.handleSelect('subtype', opt?.value)
          }
        />
        {!!children &&
          typeof children === 'function' &&
          children({
            onClick: this.handleCreateTicketClick,
          })}
      </React.Fragment>
    );
  }
}

const WrappedCreateTicketWrapper = props => (
  <AppContext.Consumer>
    {({ userProfile, selectedCustomer }) => (
      <CreateTicketWrapper
        {...props}
        clientName={selectedCustomer.name}
        client={selectedCustomer}
        userProfile={userProfile}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedCreateTicketWrapper;
