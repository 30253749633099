import React from 'react';
import { NavLink } from 'react-router-dom';

import './RegistrationBreadcrumb.scss';

const RegistrationBreadcrumb = ({ tabs, location }) => {
  return (
    <div className="breadcrumb-container">
      <ul>
        {tabs.map(t => (
          <li key={t.step}>
            <NavLink
              id={t.name.replace(' ', '-')}
              exact={t.exact}
              to={`${location}${t.route}`}
              activeClassName="selected">
              {t.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RegistrationBreadcrumb;
