import React from 'react';

import './StatsBarWithGap.scss';

const StatsBarWithGap = ({ boxes, className, ...otherProps }) => (
  <div className={`StatsBarWithGap ${className || ''}`} {...otherProps}>
    {!!boxes && boxes.map((box, i) => <StatBox {...box} key={i} />)}
  </div>
);

const StatBox = ({ title, value, color, description }) => (
  <div className={`StatBox ${color}`}>
    <div className="title">{title}</div>
    <div
      className="value"
      style={typeof description === 'undefined' ? { fontSize: '42px' } : null}>
      {value}
    </div>
    <div className="description">{description}</div>
  </div>
);

export default StatsBarWithGap;
