import React from 'react';
import { WidgetDialGaugeGraph } from '../../components';
import { Dashboard, usePromise } from '../../api';
import { widgetClick } from '../../utils/analytics';
import { numberWithCommas } from '../../utils/utils';

import './WidgetTicketSatisfactionRating.scss';

export default function WidgetTicketSatisfactionRating({
  className,
  ...otherProps
}) {
  const { response, isLoading } = usePromise(Dashboard.csatPercentage);
  const data = response?.body?.data;
  const percent = data?.satisfaction_percentage;
  const count = data?.rating_count;

  const goToTickets = () => {
    const link = '/my-organization/tickets?tab=resolved';
    widgetClick({ link, name: 'WidgetTicketSatisfactionRating' });
  };

  const goToCSATTickets = () => {
    const link =
      '/my-organization/tickets?tab=resolved&csat_rating_name=great%7Cacceptable%7Cbad';
    widgetClick({ link, name: 'WidgetTicketSatisfactionRating' });
  };

  return (
    <WidgetDialGaugeGraph
      onClick={goToCSATTickets}
      isLoading={isLoading}
      title="Satisfaction Rating"
      subtitle="in last 90 days"
      className="WidgetTicketSatisfactionRating"
      nullMessage={
        <>
          It looks like there are no recent ratings.
          <br />
          <button className="link primary" onClick={goToTickets}>
            Review Resolved Tickets
          </button>
        </>
      }
      percent={percent}
      graphSubtitle={`based on ${numberWithCommas(count) || 0} rating${
        count === '1' || count === 1 ? '' : 's'
      } submitted`}
    />
  );
}
