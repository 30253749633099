import React from 'react';

import format from 'date-fns/format';

import './CustomToolTipMalware.scss';

export const CustomToolTipMalware = ({
  type,
  payload,
  label,
  formatter,
  labelFormatter,
}) => {
  let otherMalware = payload.find(p => p.dataKey === 'other_event_count');
  let maliciousMalware = payload.find(p => p.dataKey === 'malicious_count');
  otherMalware = otherMalware ? otherMalware.value : 0;
  maliciousMalware = maliciousMalware ? maliciousMalware.value : 0;
  const totalMalware = otherMalware + maliciousMalware;

  return (
    <div className="CustomToolTipMalware">
      <div>
        <div>{label ? format(label, 'M/DD/YYYY') : ''}</div>
        <div className="row">
          <span className="row-title orange">Malicious</span>
          <span>{maliciousMalware}</span>
        </div>
        <div className="row">
          <span className="row-title">Other</span>
          <span>{otherMalware}</span>
        </div>
        <div className="row">
          <span className="row-title">Total</span>
          <span>{totalMalware}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomToolTipMalware;
