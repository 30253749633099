import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';
import {
  FormInput,
  FormFileUpload,
  FormTextArea,
  Success,
  FormSelect,
} from '../';

import './AddTicketModal.scss';

const AddTicketModal = ({
  isVisibleCreateTicketModal,
  componentState,
  COMPONENT_STATES,
  ...otherProps
}) => (
  <Modal
    className="AddTicketModal"
    onClose={otherProps.handleSoftCancel}
    open={isVisibleCreateTicketModal}
    size="tiny">
    {componentState === COMPONENT_STATES.SUBMITTED && (
      <ContentTicketSubmitted {...otherProps} />
    )}
    {componentState === COMPONENT_STATES.CONFIRM && (
      <ContentConfirmAction {...otherProps} />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK_CORSICA && (
      <ContentSparkCorsicaCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK_CLIENT && (
      <ContentSparkClientCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE_SPARK && (
      <ContentTicketCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.CREATE && (
      <ContentTicketCreate
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
    {componentState === COMPONENT_STATES.SPARK_SELECT_OWNER && (
      <ContentSelectClient
        isError={componentState === 'error'}
        {...otherProps}
      />
    )}
  </Modal>
);

export default AddTicketModal;

const ContentTicketSubmitted = ({
  ticketId,
  handleGoToMyTickets,
  handleGoToMyTicketDetails,
  handleGoToTicketDetails,
  handleHideTicketCreateModal,
  isSparkUser,
  selectedClientId,
  corsicaId,
}) => (
  <>
    <Modal.Content className="submitted">
      <Success />
      <h3>Ticket {ticketId} Created Successfully!</h3>
      {isSparkUser ? (
        selectedClientId === corsicaId ? (
          <p>
            Your support request is being routed to a Corsica support team
            member. Click View Ticket below to view this ticket.
          </p>
        ) : (
          <p>
            Your internal ticket has been created successfully. Click View
            Ticket below to view and manage this ticket.
          </p>
        )
      ) : (
        <p>
          {
            'Your support request is being routed to a member of our support team. You can view updates and progress for your ticket in your '
          }
          <span className="simulated-link" onClick={handleGoToMyTickets}>
            My Tickets
          </span>
          {' view.'}
        </p>
      )}
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        onClick={
          isSparkUser ? handleGoToTicketDetails : handleGoToMyTicketDetails
        }>
        View Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleHideTicketCreateModal}>
        Close
      </Button>
    </Modal.Actions>
  </>
);

const ContentConfirmAction = ({
  handleContinue,
  handleHideTicketCreateModal,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Are you sure?</h2>
    </Modal.Header>
    <Modal.Content>
      <p>If you close this window now, any information entered will be lost.</p>
    </Modal.Content>
    <Modal.Actions>
      <Button style={{ display: 'none' }}>hidden</Button>
      <Button className="ghost dark" onClick={handleContinue} active={true}>
        Continue editing
      </Button>
      <Button
        className="ghost destructive"
        onClick={handleHideTicketCreateModal}>
        Close
      </Button>
    </Modal.Actions>
  </>
);

const ContentTicketCreate = ({
  clientName,
  isError,
  handleChangeTitle,
  title,
  isErrorSubject,
  handleChangeDescription,
  description,
  isErrorIssue,
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
      {clientName && (
        <p className="subheading">
          for <strong>{clientName}</strong>
        </p>
      )}
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormInput
        style={{ width: '100%', marginBottom: '15px' }}
        label="Subject for ticket"
        onChange={handleChangeTitle}
        value={title}
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        maxLength={100}
        autoFocus={true}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        placeholder="Briefly describe the issue you are experiencing..."
        rows={10}
        onChange={handleChangeDescription}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="What can we help you with?"
        error={isErrorIssue}
      />
      <FormFileUpload
        multiple={true}
        style={{ width: '100%' }}
        onChange={handleFiles}
        label="Add screenshots if needed (JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB)"
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        error={isErrorFiles}
        errorMessage={'There was a problem uploading your file'}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={!title || !description}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

const ContentSelectClient = ({
  corsicaId,
  userProfile,
  selectedClientId,
  handleSelectClient,
  handleContinue,
  handleHideTicketCreateModal,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        label="Who will be working this ticket?"
        placeholder="Select a team"
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
    </Modal.Content>
    <Modal.Actions>
      <Button primary disabled={true}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleHideTicketCreateModal}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

const ContentSparkClientCreate = ({
  isError,
  handleChangeTitle,
  title,
  isErrorSubject,
  handleChangeDescription,
  description,
  isErrorIssue,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
  assignedTo,
  handleAssignedToChange,
  optionsPriority,
  optionsType,
  optionsSubtype,
  priority,
  type,
  subtype,
  handlePriorityChange,
  handleTypeChange,
  handleSubtypeChange,
  userProfile,
  corsicaId,
  selectedClientId,
  handleSelectClient,
  optionsAssignedTo,
  isLoadingAttributes,
  /* file upload fields */
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        label="Who will be working this ticket?"
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={optionsAssignedTo.map(opt => ({
          text: opt.name,
          value: opt.contact_id,
        }))}
        label="Who is the contact for this ticket?"
        onChange={handleAssignedToChange}
        value={assignedTo}
        isLoading={isLoadingAttributes}
      />
      <FormInput
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        label="Subject for ticket"
        maxLength={100}
        onChange={handleChangeTitle}
        style={{ width: '100%', marginBottom: '15px' }}
        value={title}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        rows={10}
        onChange={handleChangeDescription}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="Briefly describe the issue"
        error={isErrorIssue}
      />
      <div className="form-row">
        <FormSelect
          options={optionsPriority.map(opt => ({
            text: opt.name,
            value: opt.id,
          }))}
          label="Priority"
          onChange={handlePriorityChange}
          value={priority}
          isLoading={isLoadingAttributes}
        />
        <FormSelect
          options={optionsType.map(opt => ({
            text: opt.name,
            value: opt.id,
          }))}
          label="Category"
          onChange={handleTypeChange}
          value={type}
          isLoading={isLoadingAttributes}
        />
        <FormSelect
          options={optionsSubtype
            .filter(o => {
              // TODO: this should be replaced soon
              // temp filter for alert type only allowing subtypes of "hardware" and "workstation"
              if (type === 318) {
                return o.id === 364 || o.id === 372;
              }
              return true;
            })
            .map(opt => ({
              text: opt.name,
              value: opt.id,
            }))}
          label="Type"
          onChange={handleSubtypeChange}
          value={subtype}
          isLoading={isLoadingAttributes}
        />
      </div>
      <FormFileUpload
        multiple={true}
        style={{ width: '100%' }}
        onChange={handleFiles}
        label="Add screenshots if needed (JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB)"
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        error={isErrorFiles}
        errorMessage={'There was a problem uploading your file'}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={!title || !description}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);

const ContentSparkCorsicaCreate = ({
  isError,
  handleChangeTitle,
  title,
  isErrorSubject,
  handleChangeDescription,
  description,
  isErrorIssue,
  isSubmitting,
  handleSubmit,
  handleSoftCancel,
  assignedTo,
  handleAssignedToChange,
  userProfile,
  corsicaId,
  selectedClientId,
  handleSelectClient,
  optionsAssignedTo,
  isLoadingAttributes,
  /* file upload fields */
  handleFiles,
  isUploading,
  uploadProgress,
  isErrorFiles,
}) => (
  <>
    <Modal.Header style={{ borderBottom: 'none' }}>
      <h2>Create New Support Ticket</h2>
    </Modal.Header>
    <Modal.Content>
      {isError && (
        <Message negative>There was an error creating the ticket</Message>
      )}
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        label="Who will be working this ticket?"
        options={[
          { text: 'Corsica Technologies', value: corsicaId },
          { text: userProfile.client_name, value: userProfile.client_id },
        ]}
        value={selectedClientId}
        onChange={(e, opt) =>
          handleSelectClient({
            selectedClientId: opt?.value,
            selectedClientName: opt?.text,
            alsoUpdateState: true,
          })
        }
      />
      <FormSelect
        style={{ width: '100%', marginBottom: '15px' }}
        options={optionsAssignedTo.map(opt => ({
          text: opt.name,
          value: opt.contact_id,
        }))}
        label="Who is the contact for this ticket?"
        onChange={handleAssignedToChange}
        value={assignedTo}
        isLoading={isLoadingAttributes}
      />
      <FormInput
        style={{ width: '100%', marginBottom: '15px' }}
        label="Subject for ticket"
        onChange={handleChangeTitle}
        value={title}
        error={isErrorSubject}
        errorMessage={'A subject is required'}
        maxLength={100}
      />
      {!(title.length < 100) && (
        <span className="subject-length-warning">
          Please limit the subject to 100 characters.
        </span>
      )}
      <FormTextArea
        placeholder="Briefly describe the issue you are experiencing..."
        rows={10}
        onChange={handleChangeDescription}
        value={description}
        style={{ width: '100%', marginBottom: '15px' }}
        label="What can we help you with?"
        error={isErrorIssue}
      />
      <FormFileUpload
        multiple={true}
        style={{ width: '100%' }}
        onChange={handleFiles}
        label="Add screenshots if needed (JPG, PNG, PDF, CSV, DOC, DOCX, XLS, XLSX allowed - max file size 2MB)"
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        error={isErrorFiles}
        errorMessage={'There was a problem uploading your file'}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        loading={isSubmitting || isUploading}
        onClick={handleSubmit}
        disabled={!title || !description}>
        Create Ticket
      </Button>
      <Button className="ghost secondary" onClick={handleSoftCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </>
);
