import React, { Component } from 'react';
import { Modal, Button, Loader, Checkbox } from 'semantic-ui-react';
import { Users } from '../../api';

import { DisplayField, UserAccessSelect, UserRoleSelect } from '../';
import { formatPhone } from '../../utils/';

import './ModalUserEdit.scss';

class ModalUserEdit extends Component {
  _isMounted = false;
  name = '';
  constructor(props) {
    super(props);

    const {
      role,
      last_name,
      first_name,
      multi_account_access,
      is_outside_contractor,
    } = props.selectedUser || {};

    this.name = [first_name, last_name].filter(f => !!f).join(' ');

    this.state = {
      role,
      access: !!multi_account_access,
      isOCChecked: is_outside_contractor,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  handleToggleOC = () => {
    this.setState(prevState => ({
      ...prevState,
      isOCChecked: !prevState.isOCChecked,
    }));
  };

  handleUpdateRole = (e, { value }) => {
    this.setState({ role: value });
  };

  handleUpdateAccess = (e, value) => {
    this.setState({ access: value.value === 'Enabled' });
  };

  handleSubmitUserEdit = async () => {
    const { role, access, isOCChecked } = this.state;
    const { selectedUser } = this.props;

    const oldRole =
      selectedUser?.role && this.determineNumber(selectedUser.role);
    const oldAccess = selectedUser?.multi_account_access;
    const oldOC = selectedUser?.is_outside_contractor;

    const _role = this.determineNumber(role);
    const updates = { userId: selectedUser.id };

    if (_role !== oldRole) {
      updates.newRole = _role;
    }
    if (access !== oldAccess) {
      updates.newAccess = access;
    }
    if (isOCChecked !== oldOC) {
      updates.newOC = isOCChecked;
    }

    this.updateUser(updates);
  };

  updateUser = async ({ newRole, newAccess, newOC, userId }) => {
    const values = {};

    if (newAccess || newAccess === false) {
      values.multi_account_access = newAccess;
    }
    if (newRole || newRole === 0) {
      values.role = newRole;
    }
    // newOC will be a bool value, so `false` should be an actionable value
    if (newOC || newOC === false) {
      values.is_outside_contractor = newOC;
    }

    let res;

    try {
      res = await Users.update({
        user_id: userId,
        values,
      });
    } catch (err) {
      this.props.showErrorToast();
    }
    if (res) {
      if (newOC || newOC === false) {
        this.props.showSuccessToast(
          `${this.name} is ${
            newOC ? 'now' : 'no longer'
          } an Outside Contractor.`
        );
      }
      if (newRole || newRole === 0) {
        this.props.showSuccessToast(
          `${this.name}'s role successfully updated to ${this.determineString(
            newRole
          )}.`
        );
      }
      if (newAccess || newAccess === false) {
        this.props.showSuccessToast(
          `${this.name}'s multi-location access has been set to ${
            newAccess ? 'Enabled' : 'Disabled'
          }.`
        );
      }
      // really trying to avoid using redux again here. LOL
      // this is telling the user view that it needs to reload.
      this.props.setUserTableReloadKey(Math.random() * 10000);
    }
    this.safeSetState({
      showEditUserModal: false,
    });
  };

  determineString = num => {
    let res;
    switch (num) {
      case 0:
        res = 'Admin';
        break;
      case 1:
        res = 'User';
        break;
      case 2:
        res = 'IT Operations';
        break;
      case 3:
        res = 'Management';
        break;
      default:
    }
    return res;
  };

  determineNumber = string => {
    string = typeof string === 'string' ? string.toLowerCase() : string;
    let res;
    switch (string) {
      case 'admin':
        res = 0;
        break;
      case 'user':
        res = 1;
        break;
      case 'it_operations':
      case 'it operations':
        res = 2;
        break;
      case 'management':
        res = 3;
        break;
      default:
    }
    return res;
  };

  render() {
    const {
      showConfirmMFADelete,
      selectedUser,
      showEditUserModal,
      isMulti,
    } = this.props;

    const {
      email,
      mobile_phone,
      mfa_type,
      ad_user,
      id,
      multi_account_access,
      is_outside_contractor,
    } = selectedUser || {};
    const mfaType = mfa_type;
    const userId = id;
    const ssoEnabled = !ad_user && mfaType;

    const { isLoading, role, isOCChecked, access } = this.state;

    const isRoleChanged =
      (selectedUser && selectedUser.role && selectedUser.role.toLowerCase()) !==
      role.toLowerCase();
    const isOCChanged = is_outside_contractor !== isOCChecked;

    // if multi_account_access is a bool value, then we need to check it also
    let checkAccessAlso = false;
    let doesAccessMatch = true;

    if (isMulti) {
      checkAccessAlso = true;
      doesAccessMatch =
        (multi_account_access && access) || (!multi_account_access && !access);
    }

    const isAccessChanged = checkAccessAlso ? !doesAccessMatch : false;

    const buttonDisabled = !isRoleChanged && !isOCChanged && !isAccessChanged;

    return (
      <Modal
        className="ModalUserEdit"
        size={showConfirmMFADelete ? 'mini' : 'tiny'}
        open={showEditUserModal}>
        <Modal.Header>
          {showConfirmMFADelete ? (
            <h2>Reset User's MFA Method</h2>
          ) : (
            <h2>User Settings</h2>
          )}
        </Modal.Header>
        <Modal.Content style={{ minHeight: showConfirmMFADelete ? 100 : 200 }}>
          {isLoading ? (
            <Loader active />
          ) : showConfirmMFADelete ? (
            <ConfirmMFAView name={this.name} />
          ) : (
            <EditUserModalView
              email={email}
              name={this.name}
              phone={mobile_phone}
              mfaType={mfaType}
              userId={userId}
              role={role}
              handleUpdateRole={this.handleUpdateRole}
              handleUpdateAccess={this.handleUpdateAccess}
              ssoEnabled={ssoEnabled}
              multi_account_access={multi_account_access}
              multi_account_client={isMulti}
              access={access}
              isOCChecked={isOCChecked}
              handleToggleOC={this.handleToggleOC}
            />
          )}
        </Modal.Content>

        <div className="edit-user-actions">
          <Button
            onClick={
              showConfirmMFADelete
                ? this.props.resetMFA
                : this.handleSubmitUserEdit
            }
            color={showConfirmMFADelete ? 'orange' : 'blue'}
            // Always enabled for MFA reset, else on user edit, buttonDisabled toggles
            disabled={!showConfirmMFADelete && buttonDisabled}
            type="button">
            {showConfirmMFADelete ? 'Reset MFA Method' : 'Update User'}
          </Button>
          <Button
            className="ghost secondary"
            onClick={this.props.onCancel}
            type="button">
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ModalUserEdit;

const EditUserModalView = ({
  email,
  name,
  phone,
  mfaType,
  role,
  handleUpdateRole,
  ssoEnabled,
  multi_account_client,
  handleUpdateAccess,
  access,
  isOCChecked,
  handleToggleOC,
}) => (
  <div className="EditUserModalView">
    <div style={{ display: 'flex' }}>
      <div className="column col-1">
        <DisplayField title="Name" value={name} />
        {phone ? (
          <DisplayField title="Mobile Phone" value={formatPhone(phone)} />
        ) : null}
      </div>
      <div className="column col-2">
        <DisplayField title="Email" value={email} />
        {ssoEnabled && (
          <DisplayField
            title="MFA Method"
            hideNulls={false}
            value={
              !!mfaType && (
                <span style={{ textTransform: 'uppercase' }}>{mfaType}</span>
              )
            }
          />
        )}
      </div>
    </div>
    <div className="options-box">
      <DisplayField
        title="User Role"
        value={<UserRoleSelect role={role} onRoleChange={handleUpdateRole} />}
      />
      {multi_account_client ? (
        <DisplayField
          title="Multi-Location Access"
          value={
            <UserAccessSelect
              access={access ? 'Enabled' : 'Disabled'}
              onAccessChange={handleUpdateAccess}
            />
          }
        />
      ) : null}
    </div>
    <div>
      <label className="sso-label">
        <Checkbox onChange={handleToggleOC} checked={isOCChecked} />
        User is an <strong>Outside Contractor</strong> (will use standard
        account login)
      </label>
    </div>
  </div>
);

const ConfirmMFAView = ({ name }) => (
  <div>
    <p>
      Are you sure you would like to reset <strong>{name}'s</strong> MFA Method?
    </p>
    <p>
      If you reset it, the user will be guided through the MFA set up process
      again.
    </p>
  </div>
);
