import React from 'react';

import { ListWidget } from 'corsica-component-library';

export default function WidgetTopSupportDevices({ className, ...otherProps }) {
  const rows = [
    { text: 'AMFG-NET-TCAM', value: '43%' },
    { text: 'AMFG-SALE-DIR', value: '31%' },
    { text: 'ACME-ADMIN-03', value: '25%' },
    { text: 'ACME-ADMIN-AW', value: '14%' },
    { text: 'ACME-ADMIN-AC', value: '12%' },
  ];

  return (
    <ListWidget
      title="Top Support Devices"
      subtitle="in last 90 days"
      className="WidgetTopSupportDevices"
      data={rows}
      columns={1}
      rows={5}
    />
  );
}
